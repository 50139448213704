import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { toast } from 'react-toastify';
import axios from "axios";

import AuthContex from '../../context/autenticacion/authContex';
import * as ROUTES from '../../constans/rutas';
import AcumularComponent from '../acumular';

import factura from '../../asses/img/fctura.png';
import factura2x from '../../asses/img/fctura@2x.png';
import factura3x from '../../asses/img/fctura@3x.png';

const CabezeraComponent = () => {
    const authContex = useContext(AuthContex);
    const { usuario } = authContex;
    const [showp, setShowp] = useState(false);
    const [identificacion, setIdentificacion] = useState();

    let location = useLocation();

    useEffect(() => {
        if(usuario) setIdentificacion(usuario.cedula);
    }, [usuario])

    return (
        <>
            <Navbar expand="lg" className={usuario?.extracto?.categoria === "GREEN" ? "nav-perfil" : "nav-perfil azul"}>
                
                    <Nav className="mr-auto">
                        <NavLink  
                            activeClassName="active"
                            className="nav-link"
                            to={ROUTES.BEPERFIL}>
                            Tus datos
                        </NavLink>
                        <NavLink  
                            activeClassName="active"
                            className="nav-link"
                            to={ROUTES.BETUBEPARKING}>
                            Tu BeParking
                        </NavLink>
                        <NavLink  
                            activeClassName="active"
                            className="nav-link"
                            to={ROUTES.BETUSBONOS}>
                            Tus bonos
                        </NavLink>
                        <button  
                            className="nav-link"
                            onClick={()=> setShowp(true)} >
                            Quiero acumular mis puntos
                        </button>
                        <NavLink  
                            activeClassName="active"
                            className="nav-link"
                            to={ROUTES.BESOPORTE}>
                            Soporte
                        </NavLink>
                    </Nav>
                <p className="justify-content-end">Puntos acumulados en el año: <b>{usuario?.extracto?.puntosAcumulados}</b></p>
            </Navbar>

            { location.pathname === "/beparking/datos" ?

            <div className={usuario?.extracto?.categoria === "GREEN" ? 'perfil-info' : 'perfil-info azul'}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h3>!Hola¡</h3>
                        <h4>{usuario?.nombre} {usuario?.apellido}</h4>
                        <p>Tu categoría: {usuario?.extracto?.categoria}</p>
                    </Col>
                </Row>
            </div>
            : null 
            }

            <AcumularComponent 
                openAcomular={showp}
                onOpenAcomular={(estado)=> setShowp(estado)}
            />
        </>
        
    );
};

export default CabezeraComponent;