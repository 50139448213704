import React, { useEffect, useState } from 'react';
import { AiFillEdit, AiFillDelete, AiOutlinePlus } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Carousel from 'react-bootstrap/Carousel';
import Dropzone from "../dropzone";

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const EdtiComponent = ({ show, title, text, textPrimary, textSecundary, action, data, onPrimary, onSecondary, firebase, tipoBe }) => {
    
    const [index, setIndex] = useState(0);
    const [textButton, setTextButton] = useState(0);
    const [preloader, setPreloader] = useState(100);
    const [preloaderM, setPreloaderM] = useState(100);

    const [nombre, setNombre] = useState('');
    const [orden, setOrden] = useState();
    const [descripcion, setDescripcion] = useState('');
    const [imagen, setUrl] = useState();
    const [imagenM, setUrlM] = useState();
    const [idImagen, setIdImagen] = useState();
    const [idImagenM, setIdImagenM] = useState();
    const [idImagenEd, setIdImagenEd] = useState();
    const [idImagenEdM, setIdImagenEdM] = useState();
    const [nombre_original, setNombre_original] = useState();
    const [nombre_originalM, setNombre_originalM] = useState();
    const [itemId, setItemId] = useState();
    const [itemSelect, setItemSelect] = useState();
    const [tipo, setTipo] = useState();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [terminosState, setTerminosState] = useState(EditorState.createEmpty());

    useEffect(() => {
        setIndex(0);
        setUrl();
    }, [show]);

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    };
    const onTerminosStateChange = (editorState) => {
        setTerminosState(editorState)
    };

    const verEdit = (item) => {
        setItemSelect(item);
        setItemId(item._id);
        setNombre(item.nombre);
        setTipo(item.tipo);
        setOrden(item.orden);
        setIdImagenEdM(item.idImagenM);
        setIdImagenEd(item.idImagen);
        setIdImagen(item.idImagen);
        setIdImagenM(item.idImagenM);
        setDescripcion(item.descripcion);
        setUrl(item.imagen);
        setUrlM(item.imagenM);
        setNombre_original(item.nombre_original);
        setNombre_originalM(item.nombre_originalM);
        setTextButton(2);
        setIndex(1);
        if(item.noticia){
            const blocksFromHtml = htmlToDraft(item.noticia);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
        }
        if(item.termino){
            const blocksFromHtml = htmlToDraft(item.termino);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setTerminosState(editorState)
        }
    }

    const limpiarCampos = () => {
        setNombre('');
        setOrden();
        setDescripcion('');
        setUrl();
        setNombre_original();
        setItemId();
        if(action === "noticias"){
           setEditorState(EditorState.createEmpty())
        }
    }

    const resulImagen = (result, path, id) => {
        setUrl(result);
        setNombre_original(path);
        setIdImagen(id);
    }

    const resulImagenM = (result, path, id) => {
        setUrlM(result);
        setNombre_originalM(path);
        setIdImagenM(id);
    }

    const crearServicio = async () => {
        if(preloader === 100){
            const newKey = firebase.db.collection('Servicios').doc();
            const data = {
                _id: newKey.id,
                imagen: imagen, 
                idImagen: idImagen,
                nombre: nombre,
                orden: orden,
                nombre_original: nombre_original,
                descripcion: descripcion
            }
            newKey.set(data)
            .then( (docRef) => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                toast.success("Agregado con exito");
            })
            .catch( (error) => {
                toast.error(error.message);
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }
    }

    const editarServicio = async () => {
        if(preloader === 100){
            const data = {
                imagen, 
                idImagen: idImagen ? idImagen : idImagenEd,
                nombre,
                orden,
                descripcion,
                nombre_original
            }
            firebase.db.collection('Servicios').doc(itemId).update(data)
            .then( result => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                if(itemSelect.idImagen != idImagen){
                    firebase.stg.ref().child(`Imagenes/${itemSelect.idImagen}`).delete()
                }
                setItemSelect();
                toast.success("Editado con exito");
            }).catch( error => {
                toast.error("Algo salio mal editando el servicio...")
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }
    }

    const eliminarServicio = async (item) => {
        console.log("ITEM",item)
        firebase.db.collection('Servicios').doc(item._id).delete()
        .then( result => {
            firebase.stg.ref().child(`Imagenes/${item.idImagen}`).delete()
            onPrimary();
            limpiarCampos();
            toast.success("Eliminado con exito");
        }).catch( error => {
            toast.error("Algo salio mal eliminando el servicio...")
            console.log(error.response);
        })
    }

    // Alianzas

    const crearAlianza = async () => {
        if(preloader === 100){
            const newKey = firebase.db.collection('Alianzas').doc();
            const data = {
                _id: newKey.id,
                imagen: imagen, 
                idImagen: idImagen,
                nombre: nombre,
                orden: orden,
                descripcion: descripcion,
                nombre_original: nombre_original
            }
            newKey.set(data)
            .then( (docRef) => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                toast.success("Agregado con exito");
            })
            .catch( (error) => {
                toast.error(error.message);
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }
    }

    const editarAlianza = () => {
        if(preloader === 100){
            const data = {
                imagen, 
                idImagen: idImagen ? idImagen : idImagenEd,
                nombre,
                orden,
                descripcion,
                nombre_original
            }
            firebase.db.collection('Alianzas').doc(itemId).update(data)
            .then( result => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                if(itemSelect.idImagen != idImagen){
                    firebase.stg.ref().child(`Imagenes/${itemSelect.idImagen}`).delete()
                }
                setItemSelect();
                toast.success("Editado con exito");
            }).catch( error => {
                toast.error("Algo salio mal editando el banner...")
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }

    }

    const eliminarAlianza = async (item) => {
        firebase.db.collection('Alianzas').doc(item._id).delete()
        .then( result => {
            firebase.stg.ref().child(`Imagenes/${item.idImagen}`).delete()
            onPrimary();
            limpiarCampos();
            toast.success("Eliminado con exito");
        }).catch( error => {
            toast.error("Algo salio mal eliminando la alianza...")
            console.log(error.response);
        })
    }

    // Noticias

    const crearNoticias = async () => {
        if(preloader === 100){
            const f = new Date();
            const m = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
            const fecha = `${m[f.getMonth()]}, ${f.getDate()} ${f.getFullYear()}`
            const noti = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            const term = draftToHtml(convertToRaw(terminosState.getCurrentContent()));
            const newKey = firebase.db.collection('Beneficios').doc();
            const data = {
                _id: newKey.id,
                imagen: imagen ? imagen : '', 
                idImagen: idImagen ? idImagen : '',
                nombre: nombre,
                orden: orden,
                tipo: tipo,
                descripcion: descripcion,
                nombre_original: nombre_original,
                noticia: noti,
                termino: term,
                creado: fecha
            }
            newKey.set(data)
            .then( (docRef) => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                toast.success("Agregado con exito");
            })
            .catch( (error) => {
                toast.error(error.message);
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }

    }

    const editarNoticias = async () => {
        if(preloader === 100){
            const noti = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            const term = draftToHtml(convertToRaw(terminosState.getCurrentContent()));
            const data = {
                imagen, 
                idImagen: idImagen ? idImagen : idImagenEd,
                nombre,
                orden,
                descripcion,
                nombre_original,
                noticia: noti,
                termino: term,
            }
            firebase.db.collection('Beneficios').doc(itemId).update(data)
            .then( result => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                if(itemSelect.idImagen != idImagen){
                    firebase.stg.ref().child(`Imagenes/${itemSelect.idImagen}`).delete()
                }
                setItemSelect();
                toast.success("Editado con exito");
            }).catch( error => {
                toast.error("Algo salio mal editando la noticia...")
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }

    }

    const eliminarNoticias = async (item) => {
        console.log(item)
        firebase.db.collection('Beneficios').doc(item._id).delete()
        .then( result => {
            firebase.stg.ref().child(`Imagenes/${item.idImagen}`).delete()
            onPrimary();
            limpiarCampos();
            toast.success("Eliminado con exito");
        }).catch( error => {
            toast.error("Algo salio mal eliminando el baner...")
            console.log(error.response);
        })
    }

    // Banner
    const crearBanner = () => {
        if(preloader === 100){
            const newKey = firebase.db.collection('BannerBe').doc();
            const data = {
                _id: newKey.id,
                imagen: imagen, 
                imagenM: imagenM, 
                idImagen: idImagen,
                idImagenM: idImagenM,
                nombre: nombre,
                orden: orden,
                nombre_original: nombre_original,
                nombre_originalM: nombre_originalM
            }
            newKey.set(data)
            .then( (docRef) => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                toast.success("Agregado con exito");
            })
            .catch( (error) => {
                toast.error(error.message);
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }
    }

    const editarBanner = () => {
        if(preloader === 100){
            const data = {
                imagen, 
                imagenM, 
                idImagen: idImagen ? idImagen : idImagenEd,
                idImagenM: idImagenM ? idImagenM : idImagenEdM,
                nombre,
                orden,
                nombre_original,
                nombre_originalM
            }
            console.log(data)
            firebase.db.collection('BannerBe').doc(itemId).update(data)
            .then( result => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                if(itemSelect.idImagen != idImagen){
                    firebase.stg.ref().child(`Imagenes/${itemSelect.idImagen}`).delete()
                }
                setItemSelect();
                toast.success("Editado con exito");
            }).catch( error => {
                toast.error("Algo salio mal editando el banner...")
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }
    }

    const eliminarBanner = async (item) => {
        firebase.db.collection('BannerBe').doc(item._id).delete()
        .then( result => {
            firebase.stg.ref().child(`Imagenes/${item.idImagen}`).delete()
            onPrimary();
            limpiarCampos();
            toast.success("Eliminado con exito");
        }).catch( error => {
            toast.error("Algo salio mal eliminando el baner...")
            console.log(error.response);
        })
    }

    // BannerP
    const crearBannerP = () => {
        if(preloader === 100){
            const newKey = firebase.db.collection('BannerBeP').doc();
            const data = {
                _id: newKey.id,
                imagen: imagen, 
                imagenM: imagenM, 
                idImagen: idImagen,
                idImagenM: idImagenM,
                nombre: nombre,
                orden: orden,
                nombre_original: nombre_original,
                nombre_originalM: nombre_originalM
            }
            newKey.set(data)
            .then( (docRef) => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                toast.success("Agregado con exito");
            })
            .catch( (error) => {
                toast.error(error.message);
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }
    }

    const editarBannerP = () => {
        if(preloader === 100){
            const data = {
                imagen, 
                imagenM, 
                idImagen: idImagen ? idImagen : idImagenEd,
                idImagenM: idImagenM ? idImagenM : idImagenEdM,
                nombre,
                orden,
                nombre_original,
                nombre_originalM
            }
            console.log(data)
            firebase.db.collection('BannerBeP').doc(itemId).update(data)
            .then( result => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                if(itemSelect.idImagen != idImagen){
                    firebase.stg.ref().child(`Imagenes/${itemSelect.idImagen}`).delete()
                }
                setItemSelect();
                toast.success("Editado con exito");
            }).catch( error => {
                toast.error("Algo salio mal editando el banner...")
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }
    }

    const eliminarBannerP = async (item) => {
        firebase.db.collection('BannerBeP').doc(item._id).delete()
        .then( result => {
            firebase.stg.ref().child(`Imagenes/${item.idImagen}`).delete()
            onPrimary();
            limpiarCampos();
            toast.success("Eliminado con exito");
        }).catch( error => {
            toast.error("Algo salio mal eliminando el baner...")
            console.log(error.response);
        })
    }

    // Promociones

    const crearPromocion = async () => {
        if(preloader === 100){
            const f = new Date();
            const m = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
            const fecha = `${m[f.getMonth()]}, ${f.getDate()} ${f.getFullYear()}`
            const noti = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            const term = draftToHtml(convertToRaw(terminosState.getCurrentContent()));
            const newKey = firebase.db.collection('Promociones').doc();
            const data = {
                _id: newKey.id,
                imagen: imagen ? imagen : '', 
                idImagen: idImagen ? idImagen : '',
                nombre: nombre,
                orden: orden,
                descripcion: descripcion,
                nombre_original: nombre_original,
                noticia: noti,
                termino: term,
                creado: fecha
            }
            newKey.set(data)
            .then( (docRef) => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                toast.success("Agregado con exito");
            })
            .catch( (error) => {
                toast.error(error.message);
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }

    }

    const editarPromocion = async () => {
        if(preloader === 100){
            const noti = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            const term = draftToHtml(convertToRaw(terminosState.getCurrentContent()));
            const data = {
                imagen, 
                idImagen: idImagen ? idImagen : idImagenEd,
                nombre,
                orden,
                descripcion,
                nombre_original,
                noticia: noti,
                termino: term,
            }
            firebase.db.collection('Promociones').doc(itemId).update(data)
            .then( result => {
                onPrimary();
                limpiarCampos();
                setIndex(0);
                if(itemSelect.idImagen != idImagen){
                    firebase.stg.ref().child(`Imagenes/${itemSelect.idImagen}`).delete()
                }
                setItemSelect();
                toast.success("Editado con exito");
            }).catch( error => {
                toast.error("Algo salio mal editando la noticia...")
            })
        } else {
            toast.error('Debes esperar que cargue la imagen...');
        }

    }

    const eliminarPromocion = async (item) => {
        firebase.db.collection('Promociones').doc(item._id).delete()
        .then( result => {
            firebase.stg.ref().child(`Imagenes/${item.idImagen}`).delete()
            onPrimary();
            limpiarCampos();
            toast.success("Eliminado con exito");
        }).catch( error => {
            toast.error("Algo salio mal eliminando el baner...")
            console.log(error.response);
        })
    }
    
    console.log(index)
    console.log(action)
    
    return (
        <Modal show={show} onHide={onSecondary}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p className="bold d-flex flex-row justify-content-between align-items-center">{text} {index === 0 ? <Button onClick={ () => { setIndex(1); setTextButton(1)} } size="sm" variant="outline-success"><AiOutlinePlus />Agregar</Button> : null }</p>
                <Carousel activeIndex={index} controls={false} indicators={false} touch={false} fade={true}>
                    <Carousel.Item>
                        <ListGroup>
                            { data.map( item => {
                                return(
                                    <ListGroup.Item key={item._id} className="d-flex justify-content-between align-items-center">
                                        <Col xs={7} sm={7} md={7} lg={7} xl={8} className="d-flex flex-column pl-0">
                                            <strong className="mr-auto">{item.nombre}</strong>
                                            <small className="text-truncate d-inline-block">{item.descripcion}</small>
                                        </Col>
                                        <Col xs={5} sm={5} md={5} lg={5} xl={4} className="d-flex flex-row-reverse pr-0">
                                            <ButtonGroup size="sm">
                                                <Button onClick={() => verEdit(item)} size="sm" variant="outline-success"><AiFillEdit /></Button>
                                                <Button onClick={() => {action === 'Beneficios' ? eliminarNoticias(item) : action === "servicios" ? eliminarServicio(item) : action === "alianzas" ? eliminarAlianza(item) : action === "noticias" ? eliminarNoticias(item) : action === "banner" ? eliminarBanner(item) : action === "bannerP" ? eliminarBannerP(item) : action === "promociones" ? eliminarPromocion(item) : toast.error("Algo salio mal...") }} size="sm" variant="outline-success"><AiFillDelete /></Button>
                                            </ButtonGroup>
                                        </Col>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Form onSubmit={crearServicio}>
                            <Form.Group>
                                <Form.Label>{action === "banner" || action === "bannerP" ? 'Enlace' : 'Nombre'}</Form.Label>
                                <Form.Control 
                                    value={nombre}
                                    type="text" 
                                    name="nombre"
                                    placeholder={action === "banner" || action === "bannerP" ? "Url del enlace" : "Nombre del servicios"} 
                                    onChange={(e)=> setNombre(e.target.value )}
                                />
                            </Form.Group>
                            {action === "servicios" || action === "alianzas" || action === "beneficios"  ?
                                <Form.Group>
                                    <Form.Label>Tipoddd</Form.Label>
                                    <Form.Control 
                                        as="select"
                                        value={tipo}
                                        onChange={(e)=> setTipo(e.target.value )}
                                    >
                                        <option>Seleccionar tipo</option>
                                        <option>Green</option>
                                        <option>Blue</option>
                                    </Form.Control>
                                </Form.Group>
                            : null}
                            <Form.Group>
                                <Form.Label>Imgen</Form.Label>
                                <Dropzone archivo={imagen} respuesta={(result, path, uiImg) => resulImagen(result, path, uiImg)} uploader={(porcentaje) => setPreloader(porcentaje)} firebase={firebase}/>
                            </Form.Group>
                            {action === "banner" || action === "bannerP" ?
                                <Form.Group>
                                    <Form.Label>Imgen Movil</Form.Label>
                                    <Dropzone archivo={imagenM} respuesta={(result, path, uiImg) => resulImagenM(result, path, uiImg)} uploader={(porcentaje) => setPreloaderM(porcentaje)} firebase={firebase}/>
                                </Form.Group>
                            : null}
                            <Form.Group>
                                <Form.Label>Orden</Form.Label>
                                <Form.Control 
                                    value={orden}
                                    type="number"
                                    name="orden"
                                    placeholder="Orden en que se mostrara" 
                                    onChange={(e)=> setOrden(e.target.value )}
                                />
                            </Form.Group>
                            { action !== "banner"  || action !== "bannerP" ?
                            <Form.Group>
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control 
                                    value={descripcion}
                                    type="text" 
                                    name="nombre"
                                    as="textarea" 
                                    rows={3}
                                    placeholder="Descripcón del servicio" 
                                    onChange={(e)=> setDescripcion(e.target.value )}
                                />
                            </Form.Group>
                            : null
                            }
                        </Form>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Form.Label>Informacion ampliada</Form.Label>
                        <div  className="wisiwy">
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Form.Label>Terminos y condiciones</Form.Label>
                        <div  className="wisiwy">
                            <Editor
                                editorState={terminosState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onTerminosStateChange}
                            />
                        </div>
                    </Carousel.Item>
                </Carousel>
            </Modal.Body>

            <Modal.Footer>
                { action === "beneficios" || action === "promociones"  && index > 1 ?
                    <Button onClick={ () => { setIndex(index - 1) }} variant="secondary">Atras</Button>
                :
                    <Button onClick={onSecondary} variant="secondary">{textSecundary}</Button>
                }
                
                { textButton === 1 ?
                    (action === "beneficios" || action === "promociones") && index === 1 ?
                        <Button onClick={ () => { setIndex(2) }} variant="primary">Siguiente</Button>
                    : (action === "beneficios" || action === "promociones") && index === 2 ?
                        <Button onClick={ () => { setIndex(3) }} variant="primary">Siguiente</Button>
                    :
                        <Button onClick={ () => { action === "servicios" ? crearServicio() : action === "alianzas" ? crearAlianza() : action === "beneficios" ? crearNoticias() : action === "banner" ? crearBanner() : action === "bannerP" ? crearBannerP() : action === "promociones" ? crearPromocion() : toast.error("Algo salio mal...") }} variant="primary">Agregar</Button>
                : textButton === 2 ?
                    (action === "beneficios" || action === "promociones") && index === 1 ?
                        <Button onClick={ () => { setIndex(2) }} variant="primary">Siguiente</Button>
                    : (action === "beneficios" || action === "promociones") && index === 2 ?
                        <Button onClick={ () => { setIndex(3) }} variant="primary">Siguiente</Button>
                    :
                    <Button onClick={ () => { action === "servicios" ? editarServicio() : action === "alianzas" ? editarAlianza() : action === "beneficios" ? editarNoticias() : action === "banner" ? editarBanner() : action === "bannerP" ? editarBannerP() : action === "promociones" ? editarPromocion() : toast.error("Algo salio mal...") }} variant="primary">Editar</Button>
                : null
                }
                
            </Modal.Footer>
        </Modal>
    );
};

export default EdtiComponent;