import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FaEdit, FaTrash, FaSave, FaTimes, FaPlus } from 'react-icons/fa';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
 
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';
import { animateScroll as scroll } from 'react-scroll';
 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
 
import { db, stg, analytic } from '../../server/firebase';
import clienteAxios from '../../config/axios';
import AuthContex from '../../context/autenticacion/authContex';
import BannerComponent from '../../components/banner';
import FooterComponen from '../../components/footer';
import EditServicios from '../../components/modal/modalServicios';
import ModalDatos from '../../components/modal/modalDatos';
import ModalCategorias from '../../components/modal/modalCategorias';
import { useForm, Controller, Feedback, set } from 'react-hook-form';
 
import GoogleMaps from '../../components/mapa';
import { URL_SERVICIO, URL_AWS } from '../../constans/servidor';
 
import contacto from '../../asses/img/contacto.png';
import contacto2x from '../../asses/img/contacto@2x.png';
import contacto3x from '../../asses/img/contacto@3x.png';
import * as ROUTES from '../../constans/rutas';
import { now } from 'moment';

const PoliticaPage = () => {
  const authContex = useContext(AuthContex);
  const { usuario } = authContex;
  const [editAction, setEditAction] = useState('');
  const [editServivios, setEditServicios] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateServicio, setUpdateservicios] = useState(false);
  const [comunicados, setComunicados] = useState([]);
 
  const consultarComunicados = async () => {
    setLoading(true);
    try {
      /*const resultado = await db.collection('Noticias').orderBy('orden').get();*/
      const resultado = await db
        .collection('Comunicados')
        .where('estado', '==', 1)
        .get();
      const items = [];
      resultado.docs.forEach((doc) => {
        const item = doc.data();
        items.push(item);
      });
      setComunicados(items);
      setLoading(false);
    } catch (error) {
      toast.error('Algo salio mal cargando los comunicados...');
      console.log(error.message);
      setLoading(false);
    }
  };
 
  console.log('Estas son las comunicados: ', comunicados);
 
  useEffect(() => {
    analytic.logEvent('page_view', { page: `Terminos y condiciones Parking` });
    consultarComunicados();
  }, [updateServicio]);
  const userData = async () => {
    setLoading(true);
    setLoading(false);
  };
  return loading ? (
    <div className="cargando">
      <div className="loader" id="loader">
        Loading...
      </div>
    </div>
  ) : (
    <>
      <Container className="container-Noticias">
        <Col xs={12} sm={10} md={9} lg={10} xl={10}>
          <h3 className="texto-titulo">Línea de transparencia</h3>
          <br />
          <p className="text-Ptee">
            En PARKING INTERNATIONAL la transparencia es un valor fundamental
            que guía todas nuestras acciones y decisiones. Por lo que estamos
            firmemente comprometidos con la transparencia y el cumplimiento de
            rigurosos estándares éticos, así como en la prevención de
            actividades ilícitas. En consecuencia, la compañía ha desarrollado
            diversos mecanismos para garantizar la efectividad de dicho
            compromiso y estos están a disposición de todos nuestros
            colaboradores y grupos de interés. A partir de lo anterior, nos
            enorgullece presentar nuestra Línea de Transparencia, donde
            compartimos nuestro firme compromiso con la honestidad, la
            integridad y la responsabilidad en todas nuestras operaciones.
            Creemos que la confianza de nuestros clientes, colaboradores y
            aliados comerciales se basa en la claridad y la apertura, y estamos
            comprometidos a brindar esa confianza en cada interacción que
            tenemos.
            <br />
            En PARKING INTERNATIONAL entendemos que la transparencia no es solo
            una palabra, sino una filosofía que respaldamos con acciones
            concretas. Nos esforzamos por ser un modelo a seguir en nuestra
            industria, manteniendo altos estándares éticos y legales en todo
            momento. A través de esta Línea de Transparencia, le invitamos a
            conocer más sobre nuestras políticas internas, nuestra gobernanza
            empresarial y nuestros esfuerzos por promover un impacto positivo en
            la comunidad.
            <br />
            Para garantizar que la transparencia y la integridad sean pilares
            fundamentales de nuestra operación, ponemos a disposición de todas
            nuestras contrapartes un canal de denuncias y reportes de cualquier
            hecho o señal de alerta, de manera confidencial y anónima cuando así
            lo deseen, de acuerdo con nuestra política de protección a
            denunciantes. Este canal corresponde al correo electrónico{' '}
            <a href="mailto:oficial.cumplimiento@parking.net.co">
              oficial.cumplimiento@parking.net.co{' '}
            </a>
            , el cual está diseñado para ofrecer un espacio seguro para expresar
            sus preocupaciones y garantizar que tomemos medidas adecuadas para
            abordarlas. Su participación es esencial en nuestro esfuerzo
            continuo por mantener los más altos estándares de transparencia y
            responsabilidad. Le agradecemos por confiar en nosotros y por ser
            parte de este compromiso hacia la transparencia y la
            responsabilidad.
          </p>
        </Col>
        {usuario?.cms ? (
          <Button
            onClick={() => {
              setEditAction('comunicado');
              setEditServicios(true);
            }}
            variant="outline-success"
            className="mtop-20 btn_banner"
          >
            <AiFillEdit /> Editar
          </Button>
        ) : null}
        {comunicados.map((item, index) => {
          return (
            <>
              {item.estado == 1 ? (
                <>
                  <div key={item._id} className="card card-comunicado">
                    <Link
                      key={item.nombre}
                      className="card-title"
                      to={{
                        pathname: `/politica-PTEE/${
                          item._id
                        }/${item.nombre.replace(/\s+/g, '_')}`,
                        item,
                      }}
                    >
                      <img src={item.imagen_referencia} alt={item._id} />
                    </Link>
 
                    <div key={item._id} className="card-content">
                      <h2 className="card-date">{item.creado}</h2>
                      <Link
                        key={item.idImagen}
                        className="card-title"
                        to={{
                          pathname: `/politica-PTEE/${
                            item._id
                          }/${item.nombre.replace(/\s+/g, '_')}`,
                          item,
                        }}
                      >
                        {item.nombre}
                      </Link>
                    </div>
                    <Link
                      key={item.idImagenRef}
                      className="btn btn-leer btn-sm"
                      to={{
                        pathname: `/politica-PTEE/${
                          item._id
                        }/${item.nombre.replace(/\s+/g, '_')}`,
                        item,
                      }}
                    >
                      Leer más
                    </Link>
                  </div>
                </>
              ) : null}
            </>
          );
        })}
      </Container>
      <br />
 
      <FooterComponen />
 
      {usuario && usuario.cms ? (
        <>
          <EditServicios
            show={editServivios}
            title={editAction === 'comunicado' ? 'Editar comunicados' : null}
            text={
              editAction === 'comunicado'
                ? 'Agrega, edita o elimina comunicados.'
                : null
            }
            textPrimary="Finalizar"
            textSecundary="Cancelar"
            onSecondary={() => setEditServicios(false)}
            onPrimary={() =>
              editAction === 'comunicado'
                ? setUpdateservicios(!updateServicio)
                : null
            }
            action={editAction}
            data={editAction === 'comunicado' ? comunicados : []}
            firebase={{ db, stg }}
          />
        </>
      ) : null}
    </>
  );
};
export default PoliticaPage;